import React, { useState, useEffect } from 'react';
import Table from './components/Table';
import { fetchResources, fetchProjects, fetchPlans, addPlan, updatePlan, removePlan, updateHours } from './api';

function App() {
  const [resources, setResources] = useState([]);
  const [projects, setProjects] = useState([]);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const [resourcesData, projectsData, plansData] = await Promise.all([
        fetchResources(),
        fetchProjects(),
        fetchPlans()
      ]);
      setResources(resourcesData);
      setProjects(projectsData);
      setPlans(plansData);
    };
    loadData();
  }, []);

  const handleAddPlan = async (plan) => {
    const newPlan = await addPlan(plan);
    setPlans([...plans, newPlan]);
  };

  const handleUpdatePlan = async (id, updatedPlan) => {
    const plan = await updatePlan(id, updatedPlan);
    setPlans(plans.map(p => (p.id === id ? plan : p)));
  };

  const handleRemoveProject = async (planId) => {
    await removePlan(planId);
    setPlans(plans.filter((itm) => itm.id != planId));
  };

  const handleHoursChange = async (planId, hours) => {

    await updateHours(planId, hours);

    const planIndex = plans.findIndex((itm) => itm.id === planId);

    // If the plan is not found, exit the function
    if (planIndex === -1) return;

    // Create a new copy of the plans array
    const updatedPlans = [...plans];

    // Update the specific project’s hours within the copied array
    updatedPlans[planIndex] = {
      ...updatedPlans[planIndex],
      hours: hours
    };

    // Set the updated array in state
    setPlans(updatedPlans);

    console.log(updatedPlans); // Check the updated plans array
  }

  return (
      <div style={{ userSelect: 'none' }}>
        <h1>Resource Planner</h1>
        <Table
            resources={resources}
            projects={projects}
            plans={plans}
            onAddPlan={handleAddPlan}
            onUpdatePlan={handleUpdatePlan}
            onRemoveProject={handleRemoveProject}
            onHoursChange = {handleHoursChange}
        />
      </div>
  );
}

export default App;