import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@mui/material';

function AddProjectDialog({ open, onClose, projects, onAddProject }) {
    const [selectedOption, setSelectedOption] = useState('project'); // 'project', 'holiday', or 'custom'
    const [selectedProject, setSelectedProject] = useState(null);
    const [customName, setCustomName] = useState('');
    const [hours, setHours] = useState(8);

    const handleAddProject = () => {
        let project;

        if (selectedOption === 'project') {
            if (!selectedProject) return; // Ensure a project is selected
            project = { ...selectedProject };
        } else if (selectedOption === 'holiday') {
            project = { id: -1, name: 'Holiday' };
        } else if (selectedOption === 'custom') {
            if (!customName) return; // Ensure custom text is entered
            project = { id: -2, name: customName }; // Use a unique ID for custom entries
        }

        onAddProject({ project, hours });
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add Project</DialogTitle>
            <DialogContent>
                {/* Radio buttons for selection */}
                <FormControl component="fieldset" fullWidth>
                    <RadioGroup
                        row
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                    >
                        <FormControlLabel value="project" control={<Radio />} label="Select Project" />
                        <FormControlLabel value="holiday" control={<Radio />} label="Holiday Booking" />
                        <FormControlLabel value="custom" control={<Radio />} label="Custom" />
                    </RadioGroup>
                </FormControl>

                {/* Conditional rendering based on selected option */}
                {selectedOption === 'project' && (
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Select Project</InputLabel>
                        <Select
                            value={selectedProject ? selectedProject.id : ''}
                            onChange={(e) => {
                                const project = projects.find(p => p.id === e.target.value);
                                setSelectedProject(project);
                            }}
                            label="Select Project"
                        >
                            {projects.map((project) => (
                                <MenuItem key={project.id} value={project.id}>
                                    {project.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                {selectedOption === 'custom' && (
                    <TextField
                        label="Custom Project Name"
                        fullWidth
                        margin="normal"
                        value={customName}
                        onChange={(e) => setCustomName(e.target.value)}
                    />
                )}

                <TextField
                    label="Hours"
                    type="number"
                    fullWidth
                    margin="normal"
                    value={hours}
                    onChange={(e) => setHours(Number(e.target.value))}
                    inputProps={{ min: 1 }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleAddProject} color="primary" variant="contained">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddProjectDialog;