import React, { useState, useRef } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

function ProjectBlock({ plan, onRemoveProject, onHoursChange, onEditProject }) {
    const offset = 10; // Height per hour (20px for 1 hour, so 10px for 0.5 hour)
    const draggingRef = useRef(false); // Use ref instead of state for dragging
    const startYRef = useRef(0); // Use ref instead of state for dragging
    const currentHeightRef = useRef(plan.hours * offset); // Use ref instead of state for dragging
    const [initialHeight, setInitialHeight] = useState(plan.hours * offset);
    const [currentHeight, setCurrentHeight] = useState(plan.hours * offset); // Temporary height for live resizing

    const handleMouseDown = (e) => {
        e.stopPropagation();
        draggingRef.current = true; // Set draggingRef to true
        startYRef.current = e.clientY;
        setInitialHeight(plan.hours * offset);

        // Add event listeners to handle the dragging effect
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        if (!draggingRef.current) return; // Use ref instead of state

        // Calculate how much the mouse has moved
        const deltaY = e.clientY - startYRef.current;

        // Calculate new height based on the change in height, snapping to 10px increments
        const snappedHeight = Math.round((initialHeight + deltaY) / 10) * 10;
        const newHeight = Math.max(0, snappedHeight); // Ensure height is non-negative

        // Set current height to show live resizing
        currentHeightRef.current = newHeight;
        setCurrentHeight(newHeight);
    };

    const handleMouseUp = () => {
        draggingRef.current = false; // Reset draggingRef to false
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);

        // Calculate final hours based on snapped height and call onHoursChange
        const newHours = Math.round((currentHeightRef.current / offset) * 2) / 2; // Snap to nearest 0.5 hours
        onHoursChange(plan.id, newHours); // Only call onHoursChange on mouse up
    };

    return (
        <div
            style={{
                position: 'relative',
                backgroundColor: plan.name !== 'Holiday'?'#a3d5a4':'#CCCCCC',
                marginBottom: '5px',
                padding: '5px',
                height: `${currentHeight}px`, // Use currentHeight for live resizing
                border: '1px solid #ccc',
                borderLeft: plan.name !== 'Holiday'?'5px solid rgba(11, 106, 11, 0.9)':'5px solid #666',
                borderRadius: '6px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                fontSize:'11px'
            }}
        >
            <span>{plan.name} ({Math.round((currentHeight / offset) * 2) / 2}h)</span> {/* Display live hours */}
            {/* Icons visible on hover */}
            <div style={{ position: 'absolute', top: '2px', right: '2px', display: 'flex', gap: '5px', opacity: 1, transition: 'opacity 0.2s' }} className="hover-icons">
                <IconButton
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        onEditProject(plan); // Only open edit dialog when clicking this icon
                    }}
                    style={{ fontSize: '0.8rem' }} // Make the icon smaller
                >
                    <EditIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        onRemoveProject(plan.id);
                    }}
                    style={{ fontSize: '0.8rem' }} // Make the icon smaller
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            </div>
            {/* Resize handle */}
            <div
                onMouseDown={handleMouseDown}
                style={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    height: '5px',
                    cursor: 'ns-resize',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                }}
            />
        </div>
    );
}

export default ProjectBlock;