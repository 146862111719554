import React from 'react';
import ProjectBlock from './ProjectBlock';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { format } from 'date-fns';

function ProjectCell({ day, resource, plans, projects, onAddPlan, onRemoveProject, onHoursChange, onEditProject }) {
    const formattedDay = format(day, 'yyyy-MM-dd');

    // Filter plans based on matching date and resource ID
    const filteredPlans = (Array.isArray(plans) ? plans : []).filter(
        (plan) => plan.userId === resource.accountId && format(new Date(plan.date), 'yyyy-MM-dd') === formattedDay
    );

    // Handle add project button click
    const handleAddProject = () => {
        onAddPlan(resource.accountId, day ); // Pass `resourceId` as `userId`
    };

    return (
        <div style={{ position: 'relative', minHeight: '50px', padding: '5px', borderRight:'1px solid #ccc',height:'120px',backgroundImage: `
                    repeating-linear-gradient(
                        to bottom,
                        transparent,
                        transparent 19px,
                        #efefef 19px,
                        #efefef 20px
                    )`,
            backgroundSize: '20px 100%' }}>
            {filteredPlans.map((plan, index) => (
                <ProjectBlock
                    key={`${resource.accountId}-${day}-${index}-${plan.id}`}
                    plan={plan}
                    projects={projects}
                    onRemoveProject={onRemoveProject}
                    onHoursChange={onHoursChange}
                    onEditProject={onEditProject}
                />
            ))}
            {/* Add project button */}
            <IconButton
                onClick={handleAddProject}
                style={{
                    position: 'absolute',
                    bottom: '5px',
                    right: '5px',
                    backgroundColor: '#f0f0f0',
                    padding: '2px',
                }}
                size="small"
                aria-label="add project"
            >
                <AddIcon fontSize="small" />
            </IconButton>
        </div>
    );
}

export default ProjectCell;