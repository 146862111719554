// EditProjectDialog.js

import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';

function EditProjectDialog({ open, onClose, project, onEditProject }) {
    const [hours, setHours] = useState(project ? project.hours : 8);

    useEffect(() => {
        if (project) {
            setHours(project.hours);
        }
    }, [project]);

    const handleSave = () => {
        onEditProject(hours);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit Project Hours</DialogTitle>
            <DialogContent>
                <TextField
                    label="Hours"
                    type="number"
                    fullWidth
                    margin="normal"
                    value={hours}
                    onChange={(e) => setHours(Number(e.target.value))}
                    inputProps={{ min: 0.5, step: 0.5, max: 10 }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditProjectDialog;